import React from 'react';
import { colors } from './variables'
import styled from 'styled-components'

const ContentStyled = styled.div`
  padding: 0 15px;

  .social {
    iframe {
      margin-bottom :-4px;
      
      @media (min-width: 768px) {
        margin-bottom: -5px;
      }
    }
  }

  .whines {
    .whine {
      margin: 30px 0;
      word-wrap: break-word;
      
      p {
        margin: 0;
        font-size: 20px;
        line-height: 1.5;
        
        span {
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 9px;
          color: ${ colors.mediumGrey };
        }
      }
      
      .modal-footer {
        btn,
        form {
          display: inline-block;
        }
      }
    }
  }

  #show-whine {
    .modal-footer {
      border: none;
    }
  }

  #whines-pagination {
    background: #fff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    border-color: ${ colors.lightGrey };
    opacity: .9;
    
    ul {
      li {
        a {
          color: ${colors.grey };
        }
      }
      
      li.active {
        span {
          background: ${ colors.mediumGrey };
          border-color: ${ colors.mediumGrey };
        }
      }
    }
  }
`

const Content = (props) => {
  const { whines } = props

  return (
    <ContentStyled>      
      <div className="whines">
        {whines.map((whine, index) => (
          <div key={index} className="whine">
            <p>
              {whine.whine}<br />
              <span>{whine.UtcString}</span>
            </p>
            {/* <button
              className="glyphicon glyphicon-remove"
              onClick={props.remove.bind(null, whine.ref)}
            /> */}
          </div>
        ))}
        {/* @foreach($whines as $whine)
          <div id="whine-{{ $whine->id }}" className="whine">
            <p>{{ $whine->whine }}<br/>
            <span>{{ $whine->created_at }} UTC</span></p>
            <div className="tweet-whine social">
              <a href="https://twitter.com/share" className="twitter-share-button" data-lang="en" data-text="&quot;{{ $whine->whine_tweet }}&quot; - read the full whine at" data-hashtags="whinyme,crybaby,tears" data-url="{{ url('/') }}/{{ $whine->id }}" data-count="none">Tweet</a> this whine <span className="glyphicon glyphicon-hand-up"></span>
              <script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src="https://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");</script>
            </div>
          </div>
        @endforeach */}
      </div>
      
    {/* {{ $whines->links() }} TODO - pagination */}

    {/* @if(isset($showWhine))
      @section('show-whine')
        <div className="modal fade" id="show-whine" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <p>{{ $showWhine->whine }}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">more whines</button>
              </div>
            </div>
          </div>
        </div>		
      @stop
      @section('scripts-additional')
        <script type="text/javascript">
          $(window).load(function(){
            $('#show-whine').modal('show');
          });
          $('#show-whine').on('hide.bs.modal', function (e) {
            location.href = 'http://whiny.me';
          })
        </script>
      @stop
    @endif */}
    </ContentStyled>
  );
}

export default Content;
