import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { colors, mediaQueries } from './variables'
import styled from 'styled-components'

const validateNoLinks = (value) => {
  let error;
  if (value.includes('http')
    || value.includes('https')
    || value.includes('href=')
  ) {
    error = 'No links, please.';
  }
  return error;
}

const validateHoneyPot = (value) => {
  let error;
  if (value !== '') {
    error = 'Please do not auto-complete.';
  }
  return error;
}

class AddWhine extends React.Component{
  constructor () {
    super()
    this.state = {
      whineSubmitted: false
    }
  }
  
  render () {
    return (
      <NewWhine>
        {this.state.whineSubmitted
          ? <p>thanks for sharing :)</p>
          : (
            <Formik
              initialValues={{ whine: '', email: '' }}
              onSubmit={(values, { setSubmitting }) => {
                this.props.add(values.whine);
                this.setState({ whineSubmitted: true })
                setSubmitting(false)
              }}
              validationSchema={Yup.object().shape({
                whine: Yup.string()
                  .required('No empty whines allowed.')
                  .max(250, 'You whine too much.'),
              })}
            >
              {({ isSubmitting }) => (
                <Form>
                  <p>go on. it's ok.</p>
                  <Field
                    name="whine"
                    component="textarea"
                    placeholder="whine a little..."
                    validate={validateNoLinks}
                  />
                  <Error name="whine" component="p" />
                  <Field
                    name="email"
                    type="hidden"
                    validate={validateHoneyPot}
                  />
                  <Error name="email" component="p" />
                  {!isSubmitting
                    ? <button type="submit" disabled={isSubmitting}>share your whine</button>
                    : <button disabled="disabled">Saving your whine...</button>
                  }
                </Form>
              )}
            </Formik>
          )
        }
      </NewWhine>
    )
  }
}

export default AddWhine;

const NewWhine = styled.div`
  textarea {
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    line-height: 1.75;
    font-size: 20px;
    outline: none;
    box-sizing: border-box;
    border: none;
    border-top: 1px solid ${ colors.lightGrey };
    border-bottom: 1px solid ${ colors.lightGrey };
    
    &:focus,
    &:active {
      border: 1px solid ${ colors.lightGrey };
    }

    ${ mediaQueries.smUp } {
      border: 1px solid ${ colors.lightGrey };
      border-radius: 8px;
    }
  }

  button {
    padding: 5px 15px;
    background: ${ colors.lightGrey };
    border: 2px solid ${ colors.mediumGrey };
    border-radius: 2px;
    color: ${ colors.text };

    &:hover {
      cursor: pointer;
      border: 2px solid ${ colors.grey };
      color: ${ colors.black };
    }
  }
`

const Error = styled(ErrorMessage)`
  margin: 0 0 10px;
  color: ${ colors.red };
`
