export const colors = {
  black: '#000',
  grey: '#777',
  mediumGrey: '#999',
  lightGrey: '#e5e5e5',
  red: '#d82727',
  text: '#333',
}

export const mediaQueries = {
  xsDown: '@media (max-width: 319px)',
  xsUp: '@media  (min-width: 320px)',

  xsUpSmDown: '@media (min-width: 320px) and (max-width: 599px)',

  smDown: '@media (max-width: 599px)',
  smUp: '@media (min-width: 600px)',

  smUpMdDown: '@media (min-width: 600px) and (max-width: 959px)',

  mdDown: '@media (max-width: 959px)',
  mdUp: '@media (min-width: 960px)',

  mdUpLgDown: '@media (min-width: 960px) and (max-width: 1279px)',

  lgDown: '@media (max-width: 1279px)',
  lgUp: '@media (min-width: 1280px)',
}
