import Rebase from 're-base';
import firebase from 'firebase/app';
import 'firebase/firestore';

const app = firebase.initializeApp({
  apiKey: process.env.FB_API_KEY,
  authDomain: process.env.FB_AUTH_DOMAIN,
  databaseURL: process.env.FB_DATABASE_URL,
  projectId: 'whinyme-react',
});

const db = firebase.firestore(app);
// const settings = {/* your settings... */ timestampsInSnapshots: true};
// db.settings(settings);
const base = Rebase.createClass(db);

export default base;
