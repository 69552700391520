import React from 'react';
import base from './rebase';
import { colors, mediaQueries } from './variables';
import styled, { createGlobalStyle } from 'styled-components';
import './fonts.css'
import AddWhine from './AddWhine';
import Content from './Content';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Indie Flower', cursive;
    padding-bottom: 70px;
    text-align: center;
    
    h1 {
      margin: 20px 0 25px;
      
      a {
        color: ${ colors.text };
        text-decoration: none;
        
        &:hover {
          color: ${ colors.text };
        }
      }
    }
    
    .big {
      font-size: 20px;
      color: ${ colors.red };
    }
  }
`

const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  ${ mediaQueries.smUp } {
    max-width: 500px;
  }
`

class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      whines: [],
      loading: true
    };
  }
  
  componentDidMount () {
    this.ref = base.bindCollection('whines', {
      context: this,
      query: ref => {
        return ref
          .orderBy('dateNow', 'desc');
      },
      state: 'whines',
      withRefs: true,
      then() {
        this.setState({ loading: false });
      }
    });
  }
  
  handleAddItem (value) {
    let dateNow = Date.now();
    let UtcString = new Date().toUTCString();
    base.addToCollection('whines', {
      whine: value,
      dateNow: dateNow,
      UtcString: UtcString
    });
  }
  
  // handleRemoveItem (ref) {
  //   base.removeDoc(ref);
  // }
  
  render () {
    return (
      <Container>
        <GlobalStyle />
        <h1><a href="/">whiny me</a></h1>
        {/* <div className="tweet-site social"> TODO
          like whiny.me? <a href="https://twitter.com/share" className="twitter-share-button" data-lang="en" data-text="It's ok to #whine, so be #whiny! What's everyone #whining about?" data-hashtags="whinyme,crybaby,tears" data-url="{{ url('/') }}" data-via="adamsawicki" data-count="none">tweet</a> about us, please
          <script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src="https://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");</script>
        </div> */}
        <h2>:(</h2>
        <AddWhine add={this.handleAddItem.bind(this)} />
        {this.state.loading === true
          ? <h3> LOADING... </h3>
          : <Content
            whines={this.state.whines}
            // remove={this.handleRemoveItem.bind(this)}
          />
        }
      </Container>
    );
  }
}

export default App;
